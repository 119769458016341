import styled from 'styled-components'

const Credit = styled.div`
  background: rgba(0, 0, 0, 0.3);
  color: white;
  position: fixed;
  z-index: 3;
  right: 0;
  bottom: calc(75% - 1px);
  padding: 3px 5px;
  border-radius: 5px 0 0 0;
  font-size: .8em;
  a:link,a:visited {
    color: #6bef8d;
  }
  @media (min-width: 768px) { 
    bottom: calc(50% - 1px);
  }
`

export { Credit }
