export const Kai = {
  fur: '#7A80FF',
  stripes: '#511C92',
  belly: '#F6F6F6',
  paws: '#F6F6F6',
  eyes: '#6AF08C',
  hair: '#6AF08C',
  nose: '#D783FF',
  beans: '#EABFFF'
}

export const BG = {
  primary: '#b58ae7',
  secondary: '#dec9f8',
  tertiary: '#d6d4ff'
}

export const Social = {
  Twitter: '#1da1f2',
  Telegram: '#0088cc',
  GitLab: '#e24329',
  Mastodon: '#5959ff',
  LastFm: '#d51007',
  Bluesky: '#3078f8',
  Signal: '#3f6ae7'
}
