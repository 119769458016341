import React from 'react'
import {
  LinkCol,
  Link as A,
  LinkIcon
} from './styles'

const Link = props => (
  <LinkCol xs={6} md={2}>
    <A rel={props.rel} href={props.href} color={props.color} subtitleHidden={props.subtitle === undefined}>
      <LinkIcon icon={props.icon} />
      {props.title}
      <span class="subtitle">
        {props.subtitle}
      </span>
    </A>
  </LinkCol>
)

export default Link
