import styled from 'styled-components'
import { Container, Row } from 'reactstrap'

const LinkContainer = styled(Container)`
  height: 75%;
  position: fixed;
  top: 25%;
  padding: 0;
  z-index: 4;
  @media (min-width: 768px) { 
    height: 50%;
    top: 50%;
  }
`

const LinkRow = styled(Row)`
  width: 100%;
  height: 100%;
`

export {
  LinkContainer,
  LinkRow
}
