import React, { Fragment } from 'react'
import Background from '../Background'
import Face from '../Face'
import ArtCredit from '../ArtCredit'
import { Title } from './styles'

const Header = () => (
  <Fragment>
    <Title>KaiTiggy</Title>
    <Background />
    <Face />
    <ArtCredit />
  </Fragment>
)

export default Header
