import styled from 'styled-components'

const Title = styled.h1`
  font-size: 3em;
  font-weight: 800;
  text-shadow: 0 3px 8px rgba(0,0,0,0.5);
  text-align: center;
  padding-top: 30px;
  width: 100%;
  color: white;
  position: fixed;
  z-index: 3; 
`

export { Title }
