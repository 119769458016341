import styled from 'styled-components'
import { Alert as A } from 'reactstrap'
import { FontAwesomeIcon as Icon } from '@fortawesome/react-fontawesome'

const Alert = styled(A)`
  z-index: 5;
  width: calc(100% - 2em);
  position: fixed;
  top: 1em;
  left: 1em;
  display: flex;
  align-items: center;
  justify-content: center;
  @media (min-width: 768px) { 
    width: 50%;
  }
`

const AlertIcon = styled(Icon)`
`

const AlertText = styled.div`
  font-size: .8em;
  margin-left:  1em;
`

export { Alert, AlertIcon, AlertText }
