import styled from 'styled-components'
import { Col } from 'reactstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

const LinkCol = styled(Col)`
  text-align: center;
`

const Link = styled.a`
  font-size: 1.5em;
  line-height: 1.2em;
  width: 100%;
  height: 100%;
  padding: 0 15px;
  background: ${props => props.color || 'black'};
  display: flex;
  flex-direction: column;
  justify-content: center;
  border-top: solid 20px transparent
  border-bottom: solid 20px transparent;
  .subtitle {
    color: white;
    border: solid white 2px;
    border-radius: 15px;
    font-size: 0.7em;
    font-weight: bold;
    display: inline-block;
    min-height: 32px;
    padding: 0 15px;
    opacity: ${props => props.subtitleHidden ? '0' : '0.8'};
    margin: 15px auto 0;
    transition: all .2s ease-out;
  }
  &:link,&:visited {
    color: white;
  }
  &:hover {
    text-decoration: none;
    .subtitle {
      // color: ${props => props.color || 'black'};
      // background-color: white;
      opacity: ${props => props.subtitleHidden ? '0' : '0.8'};
    }
  } 
  @media (min-width: 768px) { 
    transition: all .2s ease-out;
    border-bottom: solid 20px rgba(0,0,0,0.3);
    .subtitle {
      opacity: ${props => props.subtitleHidden ? '0' : '0.3'};
    }
    &:hover {
      border-bottom: solid 40px rgba(0,0,0,0.4);
    } 
  }
`

const LinkIcon = styled(FontAwesomeIcon)`
  font-size: 2em;
  display: block;
  margin: 50px auto 0;
  @media (min-width: 768px) { 
    margin-top: 30px;
  }
`

export {
  LinkCol,
  Link,
  LinkIcon
}
