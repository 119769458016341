import React from 'react'
import Link from '../Link'
import {
  LinkContainer,
  LinkRow
} from './styles'
import {
  faBluesky,
  faGitlab,
  faMastodon,
  faLastfm,
  faSignalMessenger
} from '@fortawesome/free-brands-svg-icons'
import {
  faPaperPlane
} from '@fortawesome/pro-solid-svg-icons'
import { Social } from '../../utils/Colors'

const Links = () => (
  <LinkContainer fluid>
    <LinkRow noGutters>
      <Link
        href='https://t.me/kaitiggy'
        color={Social.Telegram}
        icon={faPaperPlane}
        title='Telegram'
      />
      <Link
        href='https://signal.me/#eu/Dja-S4kPeNKtrvsI0_66B_Nu_upHc5BBVPfMwniAbzqEHW4yE5FFdJAb-ea6iWhB'
        color={Social.Signal}
        icon={faSignalMessenger}
        title='Signal'
      />
      <Link
        rel='me'
        href='https://cubhub.social/@KaiTiggy'
        color={Social.Mastodon}
        icon={faMastodon}
        title='Mastodon'
        subtitle='cubhub.social'
      />
      <Link
        href='https://bsky.app/profile/kai.tiggy.social'
        color={Social.Bluesky}
        icon={faBluesky}
        title='Bluesky'
      />
      {/* <Link
        href='https://twitter.com/kaitiggy'
        color={Social.Twitter}
        icon={faTwitter}
        title='Twitter'
        subtitle='inactive'
      /> */}
      <Link
        href='https://last.fm/user/kaitiggy'
        color={Social.LastFm}
        icon={faLastfm}
        title='Last.fm'
      />
      <Link
        href='https://gitlab.com/kaitiggy'
        color={Social.GitLab}
        icon={faGitlab}
        title='GitLab'
      />
      {/* <Link
        href='https://art.kaitiggy.com/kai'
        color={Kai.stripes}
        icon={faPaintBrush}
        title='Commission Archive'
      /> */}
    </LinkRow>
  </LinkContainer>
)

export default Links
