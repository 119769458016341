import React from 'react'
import {
  BrowserRouter,
  Switch,
  Route
} from 'react-router-dom'
import { CookiesProvider } from 'react-cookie'
import Home from '../pages/Home'

export default function Router () {
  return (
    <CookiesProvider>
      <BrowserRouter>
        <Switch>
          <Route path='/'>
            <Home />
          </Route>
        </Switch>
      </BrowserRouter>
    </CookiesProvider>
  )
}
