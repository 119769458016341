import React, { Fragment } from 'react'
import { useLocation } from 'react-router-dom'
import Header from '../components/Header'
import NSFWWarning from '../components/NSFWWarning'
import Links from '../components/Links'

function useQuery () {
  return new URLSearchParams(useLocation().search)
}

function Home () {
  const query = useQuery()
  return (<Fragment>
    <Header />
    <NSFWWarning referrer={query.get('ref') || ''} />
    <Links />
  </Fragment>)
}

export default Home
