import styled, { keyframes } from 'styled-components'

const PeekInDesktop = keyframes`
  to {
    bottom: calc(50% - 1px);
  }
`

const PeekInMobile = keyframes`
to {
  bottom: calc(75% - 1px);
}
`

const SVG = styled.img`
  position: fixed;
  z-index: 2;
  width: 100%;
  padding: 0 30vw;
  text-align: center;
  bottom: calc(75% - 120px);
  animation: ${PeekInMobile} .5s cubic-bezier(.62,.28,.23,.99) forwards;
  animation-delay: .5s;
  @media (min-width: 768px) { 
    bottom: 13%;
    animation: ${PeekInDesktop} .5s cubic-bezier(.62,.28,.23,.99) forwards;
    animation-delay: .5s;
  }
`

export { SVG }
