import { keyframes } from 'styled-components'

export const slideLeft = amount => (keyframes`
  from {
    transform: translateX(0px);
  }
  to {
    transform: translateX(-${amount}px);
  }
`)
