import React, { Component } from 'react'
import { Alert, AlertIcon, AlertText } from './styles'
import { faSirenOn } from '@fortawesome/pro-regular-svg-icons'
import { withCookies } from 'react-cookie'

class NSFWWarning extends Component {
  constructor (props) {
    super(props)
    const { cookies, referrer } = props

    this.state = {
      isOpen: !cookies.get('gitlabDismissed') && referrer === 'gitlab'
    }

    this.toggle = this.toggle.bind(this)
  }

  toggle () {
    const { cookies } = this.props

    this.setState({
      isOpen: !this.state.isOpen
    }, () => {
      cookies.set('gitlabDismissed', true, {
        expires: new Date(new Date().setFullYear(new Date().getFullYear() + 1))
      })
    })
  }

  render () {
    return (
      <Alert
        color='warning'
        isOpen={this.state.isOpen}
        toggle={this.toggle}
      >
        <AlertIcon icon={faSirenOn} size='3x' />
        <AlertText>
          <strong>Hey GitLab visitor!</strong>&nbsp;
          Just in case you came here through my IRL profiles, please be aware that
          the links contained in this website are <em>Not Safe for Work</em>. These
          profiles are where I express myself freely online, and I ask that you
          keep this in mind as you browse this website.
        </AlertText>
      </Alert>)
  }
}

export default withCookies(NSFWWarning)
