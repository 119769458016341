import styled from 'styled-components'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { BG } from '../../utils/Colors'
import { slideLeft } from '../../utils/Animations'

const Page = styled.div`
  width: 120vw;
  height: 25%;
  background: ${BG.primary};
  text-overflow: hidden;
  overflow: hidden;
  white-space: nowrap;
  position: fixed;
  z-index: 0;
  @media (min-width: 768px) { 
    height: 50%;
    animation: ${slideLeft(160)} 4s linear infinite;
  }
`

const IconRow = styled.div`
  margin: 30px 0;
  &:first-of-type {
    margin-top: 5px;
  }
  &:nth-of-type(even) {
    margin-left: -80px;
  }
`

const Icon = styled(FontAwesomeIcon)`
  font-size: 5em;
  color: ${BG.secondary};
  margin-right: 70px;
`

export { Page, IconRow, Icon }
