import React from 'react'
import { SVG } from './styles'

const Face = () => (
  <SVG
    src='peek-face.svg'
    beforeInjection={svg => {
      svg.classList.add('svg-class-name')
      svg.setAttribute('style', 'height: 300px;')
    }}
  />
)

export default Face
