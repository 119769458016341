import React from 'react'
import { Credit } from './styles'

const ArtCredit = () => (
  <Credit>
    Art by&nbsp;
    <a
      href='https://twitter.com/aitokitsune'
      target='_blank'
      rel='noopener noreferrer'
    >@AitoKitsune</a>
  </Credit>
)

export default ArtCredit
