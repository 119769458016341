import React, { Component } from 'react'
import { faStar } from '@fortawesome/pro-solid-svg-icons'
import { Page, IconRow, Icon } from './styles'

export default class Background extends Component {
  constructor () {
    super()
    this.state = {
      numWide: 0,
      numHigh: 0
    }
  }

  onWindowSizeChange () {
    this.setState({
      numWide: Math.ceil(window.innerWidth / 90),
      numHigh: Math.ceil(window.innerHeight / 80)
    })
  }

  componentDidMount () {
    this.onWindowSizeChange()
    window.addEventListener('resize', this.onWindowSizeChange.bind(this))
  }

  render () {
    return (
      <Page>
        {[...Array(this.state.numHigh)].map((e, i) =>
          <IconRow>
            {[...Array(this.state.numWide)].map((e, i) =>
              <Icon key={i} icon={faStar} />)}
          </IconRow>
        )}
      </Page>)
  }
}
